export default {
    accountMenu: {
        sessions: 'Sessions futures et récentes',
        notifications: 'Notification',
        support: 'Aide et support',
        information: 'Informations légales',
        settings: 'Paramètres du compte',
        messages: 'Messages',
        'expert-profile': 'Profil de l\'expert'
    },
    menu: {
        'bb-client': 'Commencez à vous développer avec BB',
        'bb-expert': 'Devenez Expert BB',
        home: 'Accueil',
        blog: 'Blog et actus'
    },
    registration: 'Inscription',
    enter: 'Saisir',
    enterAccount: 'Saisir le compte',
    account: 'Mon compte',
    logout: 'Déconnexion',
    decline: 'Refuser',
    send: 'Envoyer',
    deleteAcc: 'Supprimer le compte',
    footer: {
        faq: 'FAQ',
        policy: 'Politique de confidentialité'
    },
    session: {
        upcoming: 'Prochaines sessions',
        requested: 'Sessions demandées',
        recent: 'Sessions récentes',
        cancelReason: 'Saisissez une raison pour l\'annulation de la session',
        reasonPlaceholder: 'Décrivez la raison du refus',
        decline: 'Refuser la session',
        confirm: 'Confirmer la session',
        join: 'Rejoindre la session',
        start: 'Commencer la session',
        finish: 'Terminer la session',
        comments: 'Commentaires',
        myComments: 'Mes commentaires',
        addComment: 'Ajouter un nouveau commentaire',
        commentPlaceholder: 'Votre commentaire',
        clientComments: 'Commentaires du client',
        coachComments: 'Commentaires du coach'
    },
    room: {
        upcoming: 'Salles futures',
        requested: 'Salles demandées',
        recent: 'Salles récentes',
        newRoom: 'Nouvelle salle'
    },
    agreementText: 'J\'ai lu et j\'accepte les dispositions de l\'Accord Utilisateur et de la',
    userAgreement: '',
    privacyPolicy: 'Politique de Confidentialité',
    readMore: 'En savoir plus',
    photoDesc: 'Ajoutez une photo réelle, le visage d\'une personne est toujours plus crédible.',
    dayStart: 'Début de la journée',
    topic: 'Sujet',
    name: 'Prénom',
    surname: 'Nom de famille',
    password: 'Passe',
    birthday: 'Date de naissance',
    oldPass: 'Ancien mot de passe',
    newPass: 'Nouveau mot de passe',
    confirmPass: 'Confirmer le mot de passe',
    forgotPass: 'Mot de passe oublié',
    resetPassText: 'Un lien pour réinitialiser votre mot de passe a été envoyé à votre adresse e-mail',
    or: 'ou',
    facebook: 'Compte Facebook',
    apple: 'Compte Apple',
    google: 'Compte Google',
    becomeExpert: '',
    insertInfo: 'Insérez vos informations personnelles pour commencer votre voyage en tant qu\'expert BBuddy',
    changeUserData: 'Vos informations peuvent être ajoutées ou modifiées à tout moment',
    price: 'Prix',
    duration: 'Durée',
    search: 'Recherche',
    searchExpert: 'Rechercher un expert',
    sort: 'Trier',
    sortPriceAsc: 'Par prix croissant',
    sortPriceDesc: 'Par prix décroissant',
    details: 'Détails',
    sessionLang: 'Langue de la session',
    direction: 'Direction',
    fromTo: 'de $ à $',
    apply: 'Appliquer',
    save: 'Sauvegarder',
    edit: 'Modifier',
    changePass: 'Modifier le mot de passe',
    resetPass: 'Réinitialiser le mot de passe',
    getStarted: 'Commencer',
    delete: 'Supprimer',
    today: 'Aujourd\'hui',
    back: 'Retour',
    backToExperts: 'Retour à la liste d\'experts',
    courseInfo: 'Infos sur le cours',
    expertBackground: 'Antécédents de l\'expert',
    profCertification: 'Certification professionnelle',
    practiceHours: 'Heures de pratique',
    supervisionCount: 'Supervision par an',
    outOf: 'sur',
    schedule: 'Programme',
    successfulCase: 'Cas réussis de la pratique',
    signUp: 'Inscrivez-vous maintenant',
    noData: 'Aucune donnée',
    notFound: 'Non trouvé',
    skillsInfo: 'Infos sur les compétences',
    trainings: 'Formations',
    seminars: 'Séminaires',
    courses: 'Cours',
    mba: 'Infos Maîtrise en gestion',
    aboutCoach: 'À propos du coach',
    education: 'Éducation',
    coaching: 'Coaching',
    experiences: 'Expérience pratique',
    payInfo: 'Infos sur le paiement',
    sessionDuration: 'Durée de la session',
    experienceHours: 'Heures totales d\'expérience pratique',
    topics: 'Sujets',
    selectTopic: 'Sélectionnez un sujet',
    title: 'Titre',
    description: 'Description',
    sessionCost: 'Coût de la session en euros',
    yourTimezone: 'Votre fuseau horaire',
    workTime: 'Heures de travail',
    startAt: 'Commencer à',
    finishAt: 'Finir à',
    day: 'Jour',
    addWorkingHours: 'Ajouter des heures de travail',
    specialisation: 'Spécialisation',
    selectSpecialisation: 'Sélectionnez votre spécialisation pour continuer',
    fillWeeklySchedule: 'Remplissez votre emploi du temps hebdomadaire',
    beneficiaryName: 'Nom du bénéficiaire',
    bicOrSwift: 'Code BIC/Swift',
    association: 'Association',
    level: 'Niveau',
    addDiploma: 'Ajouter un diplôme',
    university: 'Institution',
    sunday: 'Di',
    monday: 'Lu',
    tuesday: 'Ma',
    wednesday: 'Me',
    thursday: 'Je',
    friday: 'Ve',
    saturday: 'Sa',
    addNew: 'Ajouter un nouveau',
    mExperiences: 'Expérience en gestion',
    errors: {
        invalidEmail: 'L\'adresse e-mail n\'est pas valide',
        emptyEmail: 'Veuillez saisir votre e-mail',
        emptyPass: 'Veuillez saisir votre mot de passe',
        confirmPass: 'Veuillez confirmer votre mot de passe',
        notMatchPass: 'Les nouveaux mots de passe que vous avez saisis ne sont pas identiques',
        emptyCancelReason: 'Veuillez saisir la raison',
        approvingSession: 'Erreur lors de l\'approbation de la session',
        finishingSession: 'Erreur lors de la fin de la session',
        emptyComment: 'Veuillez saisir votre commentaire',
    },
}
