export default {
    accountMenu: {
        sessions: 'Prossime e recenti sessioni',
        notifications: 'Notifica',
        support: 'Assistenza e supporto',
        information: 'Informazioni legali',
        settings: 'Impostazioni account',
        messages: 'Messaggi',
        'expert-profile': 'Profilo dell\'esperto'
    },
    menu: {
        'bb-client': 'Inizia a crescere con BB',
        'bb-expert': 'Diventa esperto BB',
        home: 'Home',
        blog: 'Blog&Notizie'
    },
    registration: 'Registrazione',
    enter: 'Inserisci',
    enterAccount: 'Inserisci account',
    account: 'Il mio account',
    logout: 'Disconnetti',
    decline: 'Rifiuta',
    send: 'Invia',
    deleteAcc: 'Elimina account',
    footer: {
        faq: 'Domande frequenti',
        policy: 'Informativa sulla privacy'
    },
    session: {
        upcoming: 'Prossime sessioni',
        requested: 'Sessioni richieste',
        recent: 'Sessioni recenti',
        cancelReason: 'Inserisci un motivo per l\'annullamento della sessione',
        reasonPlaceholder: 'Descrivi il motivo del rifiuto',
        decline: 'Rifiuta sessione',
        confirm: 'Conferma sessione',
        join: 'Partecipa alla sessione',
        start: 'Avvia sessione',
        finish: 'Termina sessione',
        comments: 'Commenti',
        myComments: 'I miei commenti',
        addComment: 'Aggiungi nuovo commento',
        commentPlaceholder: 'Il tuo commento',
        clientComments: 'Commenti del cliente',
        coachComments: 'Commenti dell\'allenatore'
    },
    room: {
        upcoming: 'Prossime sale',
        requested: 'Sale richieste',
        recent: 'Sale recenti',
        newRoom: 'Nuova sala'
    },
    agreementText: 'Ho letto e accetto i termini dell\'Accordo con l\'utente,',
    userAgreement: '',
    privacyPolicy: 'Informativa sulla privacy',
    readMore: 'Leggi di più',
    photoDesc: 'Aggiungi una foto vera: il volto di una persona è sempre più credibile.',
    dayStart: 'Inizio del giorno',
    topic: 'Argomento',
    name: 'Nome',
    surname: 'Cognome',
    password: 'Password',
    birthday: 'Data di nascita',
    oldPass: 'Vecchia password',
    newPass: 'Nuova password',
    confirmPass: 'Conferma password',
    forgotPass: 'Hai dimenticato la password',
    resetPassText: 'Un link per reimpostare la password è stato inviato al tuo indirizzo e-mail',
    or: 'o',
    facebook: 'Account Facebook',
    apple: 'Account Apple',
    google: 'Account Google',
    becomeExpert: '',
    insertInfo: 'Inserisci i tuoi dati personali per iniziare il tuo viaggio come esperto BBuddy',
    changeUserData: 'I tuoi dati possono essere aggiunti o modificati in qualsiasi momento',
    price: 'Prezzo',
    duration: 'Durata',
    search: 'Ricerca',
    searchExpert: 'Cerca un Esperto',
    sort: 'Ordina',
    sortPriceAsc: 'Per prezzo crescente',
    sortPriceDesc: 'Per prezzo decrescente',
    details: 'Dettagli',
    sessionLang: 'Lingua sessione',
    direction: 'Direzione',
    fromTo: 'da $ a $',
    apply: 'Applica',
    save: 'Salva',
    edit: 'Modifica',
    changePass: 'Cambia password',
    resetPass: 'Reimposta password',
    getStarted: 'Inizia',
    delete: 'Elimina',
    today: 'Oggi',
    back: 'Indietro',
    backToExperts: 'Torna all\'elenco degli esperti',
    courseInfo: 'Informazioni sul corso',
    expertBackground: 'Background esperto',
    profCertification: 'Certificazione professionale',
    practiceHours: 'Ore di pratica',
    supervisionCount: 'Supervisioni per anno',
    outOf: 'su',
    schedule: 'Programma',
    successfulCase: 'Casi di successo dalla pratica',
    signUp: 'Iscriviti ora',
    noData: 'Nessun dato',
    notFound: 'Non trovato',
    skillsInfo: 'Info su competenze',
    trainings: 'Training',
    seminars: 'Seminari',
    courses: 'Corsi',
    mba: 'Info sull\'MBA',
    aboutCoach: 'Informazioni sul coach',
    education: 'Istruzione',
    coaching: 'Coaching',
    experiences: 'Esperienza pratica',
    payInfo: 'Info pagamento',
    sessionDuration: 'Durata della sessione',
    experienceHours: 'Totale ore di esperienza pratica',
    topics: 'Argomenti',
    selectTopic: 'Seleziona l\'argomento',
    title: 'Titolo',
    description: 'Descrizione',
    sessionCost: 'Costo della sessione in euro',
    yourTimezone: 'Il tuo fuso orario',
    workTime: 'Orario di lavoro',
    startAt: 'Inizia alle',
    finishAt: 'Termina alle',
    day: 'Giorno',
    addWorkingHours: 'Aggiungi ore lavorative',
    specialisation: 'Specializzazione',
    selectSpecialisation: 'Seleziona la tua specializzazione per continuare',
    fillWeeklySchedule: 'Compila la tua agenda settimanale',
    beneficiaryName: 'Nome del beneficiario',
    bicOrSwift: 'BIC/codice Swift',
    association: 'Associazione',
    level: 'Livello',
    addDiploma: 'Aggiungi diploma',
    university: 'Istituto',
    sunday: 'Do',
    monday: 'Lu',
    tuesday: 'Ma',
    wednesday: 'Me',
    thursday: 'Gi',
    friday: 'Ve',
    saturday: 'Sa',
    addNew: 'Aggiungi nuovo',
    mExperiences: 'Esperienza manageriale',
    errors: {
        invalidEmail: 'L\'indirizzo e-mail non è valido',
        emptyEmail: 'Inserisci l\'e-mail',
        emptyPass: 'Inserisci la password',
        confirmPass: 'Conferma la password',
        notMatchPass: 'Le nuove password inserite non corrispondono',
        emptyCancelReason: 'Inserisci il motivo',
        approvingSession: 'Errore nell\'approvazione della sessione',
        finishingSession: 'Errore durante la chiusura della sessione',
        emptyComment: 'Inserisci il tuo commento',
    },
}
