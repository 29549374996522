export default {
    accountMenu: {
        sessions: 'Предстоящие и недавние сессии',
        notifications: 'Уведомления',
        support: 'Служба поддержки',
        information: 'Юридическая информация',
        settings: 'Настройки учетной записи',
        messages: 'Сообщения',
        'expert-profile': 'Профиль эксперта'
    },
    menu: {
        'bb-client': 'Начните свой рост с BB',
        'bb-expert': 'Станьте экспертом BB',
        home: 'Главная',
        blog: 'Блог и новости'
    },
    registration: 'Регистрация',
    enter: 'Войти',
    enterAccount: 'Войти в аккаунт',
    account: 'Учетная запись',
    logout: 'Выйти',
    decline: 'Отклонить',
    send: 'Отправить',
    deleteAcc: 'Удалить учетную запись',
    footer: {
        faq: 'Частые вопросы',
        policy: 'Политика конфиденциальности'
    },
    session: {
        upcoming: 'Предстоящие сессии',
        requested: 'Запрошенные сессии',
        recent: 'Недавние сессии',
        cancelReason: 'Введите причину отмены сессии',
        reasonPlaceholder: 'Опишите причину отказа',
        decline: 'Отклонить сессию',
        confirm: 'Подтвердить сессию',
        join: 'Присоединиться к сессии',
        start: 'Начать сессию',
        finish: 'Завершить сессию',
        comments: 'Комментарии',
        myComments: 'Мои комментарии',
        addComment: 'Добавить новый',
        commentPlaceholder: 'Ваш комментарий',
        clientComments: 'Комментарии клиента',
        coachComments: 'Комментарии коуча'
    },
    room: {
        upcoming: 'Предстоящие комнаты',
        requested: 'Запрошенные комнаты',
        recent: 'Недавние комнаты',
        newRoom: 'Новая комната'
    },
    agreementText: 'Я прочитал и согласен с условиями Пользовательского соглашения,',
    userAgreement: 'Пользовательского соглашения',
    privacyPolicy: 'Политикой конфиденциальности',
    readMore: 'Читать дальше',
    photoDesc: 'Добавьте реальную фотографию, ведь лицо человека всегда вызывает больше доверия.',
    dayStart: 'День начала',
    topic: 'Тема',
    name: 'Имя',
    surname: 'Фамилия',
    password: 'Пароль',
    birthday: 'Дата рождения',
    oldPass: 'Старый пароль',
    newPass: 'Новый пароль',
    confirmPass: 'Подтвердите пароль',
    forgotPass: 'Забыли пароль',
    resetPassText: 'Ссылка для сброса пароля была отправлена на ваш E-mail',
    or: 'или',
    facebook: 'Аккаунт Facebook',
    apple: 'Аккаунт Apple',
    google: 'Аккаунт Google',
    becomeExpert: '',
    insertInfo: 'Введите личные данные и начните свой путь эксперта BBuddy',
    changeUserData: 'Добавить и изменить информацию о себе можно в любое время',
    price: 'Цена',
    duration: 'Длительность',
    search: 'Поиск',
    searchExpert: 'Поиск эксперта',
    sort: 'Сортировать',
    sortPriceAsc: 'Цена по возрастанию',
    sortPriceDesc: 'Цена по убыванию',
    details: 'Информация',
    sessionLang: 'Язык сессии',
    direction: 'Направление',
    fromTo: 'от $ до $',
    apply: 'Применить',
    save: 'Сохранить',
    edit: 'Редактировать',
    changePass: 'Изменить пароль',
    resetPass: 'Сбросить пароль',
    getStarted: 'Начать работу',
    delete: 'Удалить',
    today: 'Сегодня',
    back: 'Назад',
    backToExperts: 'Вернуться к списку экспертов',
    courseInfo: 'Информация о курсе',
    expertBackground: 'Профессиональный опыт эксперта',
    profCertification: 'Профессиональная сертификация',
    practiceHours: 'Часов практики',
    supervisionCount: 'Супервизий в год',
    outOf: 'из',
    schedule: 'Расписание',
    successfulCase: 'Успешные случаи из практики',
    signUp: 'Записаться сейчас',
    noData: 'Нет данных',
    notFound: 'Не найдено',
    skillsInfo: 'Навыки',
    trainings: 'Тренинги',
    seminars: 'Семинары',
    courses: 'Курсы',
    mba: 'Информация о MBA',
    experiences: 'Практический опыт',
    aboutCoach: 'О коуче',
    education: 'Образование',
    coaching: 'Коучинг',
    payInfo: 'Платежная информация',
    sessionDuration: 'Продолжительность сессии',
    experienceHours: 'Общее количество часов практического опыта',
    topics: 'Темы',
    selectTopic: 'Выберите тему',
    title: 'Название',
    description: 'Описание',
    sessionCost: 'Стоимость сессии в евро',
    yourTimezone: 'Ваш часовой пояс',
    workTime: 'Рабочее время',
    startAt: 'Начало в',
    finishAt: 'Завершение в',
    day: 'День',
    addWorkingHours: 'Добавить рабочие часы',
    specialisation: 'Специализация',
    selectSpecialisation: 'Выберите свою специализацию для продолжения',
    fillWeeklySchedule: 'Заполните свое недельное расписание',
    beneficiaryName: 'Имя получателя',
    bicOrSwift: 'BIC/Swift код',
    association: 'Ассоциация',
    level: 'Уровень',
    addDiploma: 'Добавить диплом',
    university: 'ВУЗ',
    sunday: 'Вс',
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    addNew: 'Добавить',
    mExperiences: 'Управленческий опыт',
    errors: {
        invalidEmail: 'Адрес электронной почты недействителен',
        emptyEmail: 'Пожалуйста, введите ваш E-mail',
        emptyPass: 'Пожалуйста, введите ваш пароль',
        confirmPass: 'Пожалуйста, подтвердите ваш пароль',
        notMatchPass: 'Введенные новые пароли не совпадают',
        emptyCancelReason: 'Пожалуйста, введите причину',
        approvingSession: 'Ошибка при подтверждении сессии',
        finishingSession: 'Ошибка при завершении сессии',
        emptyComment: 'Пожалуйста, введите ваш комментарий',
    },
}
